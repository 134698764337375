$green: #15c550;
$blue: #4b9cff;

$light-background-color: #fff;
$background-color: #1b1d22;
$stroke-color: #30333c;
$placeholder-color: #111;

$default-text-color: #848fa8;
$dark-text-color: #000;
$light-text-color: #fff;
$dimmed-text-color: #4f5667;
