/* Position and size mixins */
@mixin position(
	$position,
	$top: null,
	$right: null,
	$bottom: null,
	$left: null
) {
	position: $position;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

@mixin absolute($args...) {
	@include position(absolute, $args...);
}

@mixin relative($args...) {
	@include position(relative, $args...);
}

@mixin fixed($args...) {
	@include position(fixed, $args...);
}

@mixin sticky($args...) {
	@include position(sticky, $args...);
	backface-visibility: hidden;
}

@mixin size($width, $height: $width) {
	width: $width;
	height: $height;
}

@mixin row($align: null, $justify: null) {
	display: flex;
	flex-direction: row;

    @if ($align) {
        align-items: $align;
    }

    @if ($justify) {
        justify-content: $justify;
    }
}

@mixin column($align: null, $justify: null) {
	display: flex;
	flex-direction: column;

    @if ($align) {
        align-items: $align;
    }

    @if ($justify) {
        justify-content: $justify;
    }
}

/* Font & text mixins */
@mixin text-replacement {
	text-indent: 101%;
	white-space: nowrap;
	overflow: hidden;
}

@mixin truncate-tail {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin antialiased {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
