@import "hedge/support.scss";

.download-modal {
	h1 {
		@include font($whitney, $size: 30px, $weight: 400);
		@include antialiased;
	}

	p {
		@include font($whitney, $size: 17px);
		@include antialiased;
		line-height: 1.25;
		color: $default-text-color;
		text-align: center;
		margin-top: 32px;
	}

	a {
		@include font($whitney, $size: 17px);
		@include antialiased;
		line-height: 1.25;
		color: $blue;
		text-align: center;
		margin-top: 32px;
	}

	.download-form {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.email-input {
		@include font($whitney, $size: 17px);
		@include antialiased;

		margin-top: 32px;
		padding: 10px;
		width: 280px;
		border: 1px solid #c3c8d3;
		border-radius: 3px;
		outline: 0;
		background: none;

		&::placeholder {
			color: rgba($default-text-color, 0.5);
		}

		&:focus,
		&:hover {
			border-color: $green !important;
		}
	}

	.newsletter {
		@include font($whitney, $size: 15px);
		@include antialiased;
		color: $default-text-color;
		margin-top: 10px;
	}

	.newsletter label {
		margin-left: 5px;
		cursor: pointer;
	}

	.downloads {
		margin-top: 32px;
	}

	.download:nth-of-type(2) {
		margin-left: 10px;
	}

	.download:nth-of-type(3) {
		margin-left: 10px;
	}

	.download hedge-button img {
		margin-right: 10px;
	}

	.requirements {
		@include font($whitney, $size: 15px);
		@include antialiased;
		color: $blue;
		margin-top: 10px;
	}
}
