@font-face {
	font-family: "Whitney";
	font-weight: 400;
	font-style: normal;
	src: url("../fonts/Whitney-Book.woff") format("woff");
}

@font-face {
	font-family: "Whitney";
	font-weight: 400;
	font-style: italic;
	src: url("../fonts/Whitney-BookItalic.woff") format("woff");
}

@font-face {
	font-family: "Whitney";
	font-weight: 100;
	src: url("../fonts/Whitney-Light.woff") format("woff");
}

@font-face {
	font-family: "Font Awesome";
	font-weight: 400;
	src: url("../fonts/FontAwesome-Regular-400.woff") format("woff");
}

$whitney: "Whitney", "Helvetica Neue", -apple-system, BlinkMacSystemFont,
	Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif;

@mixin font($name, $size: null, $weight: null) {
	font-family: $name;
	text-rendering: optimizeSpeed;

	@if ($size) {
		font-size: $size;
	}

	@if ($weight) {
		font-weight: $weight;
	}
}
